import { type Headline, type ComposedHeadline } from "../data/types";

const topHeadline: ComposedHeadline = {
  it: ["Sviluppo App", " con", "e altro", "e"],
  en: ["App Development", " with", "and more", "and"],
};

const attributionHeadline: Headline = {
  it: "Codice e contenuti di",
  en: "Code and content by",
};

const servicesHeadline: Headline = {
  it: "Servizi",
  en: "Services",
};

const aboutHeadline: Headline = {
  it: "Info",
  en: "About",
};

const contactHeadline: Headline = {
  it: "Contatto",
  en: "Contact",
};

const thoughtsHeadline: Headline = {
  it: "Pensieri e argomenti",
  en: "Thoughts and topics",
};

const linksHeadline: Headline = {
  it: "Collegamenti",
  en: "Links",
};

const unavailableHeadline: Headline = {
  it: "Nessun elemento disponibile. Premi '+' per iniziare.",
  en: "No items available. Press the '+' button to start.",
};

const loadingMessage: Headline = { it: "Carico...", en: "Loading..." };

const deletingPensieroMessage: Headline = {
  it: "Stai per cancellare questo pensiero dall'archivio",
  en: "You are about to delete this thought from the archive",
};

const missingDataMessage: Headline = {
  it: "Dati non completi. Inserisci i dati mancanti",
  en: "Data not complete. Please fill in missing fields",
};

export {
  topHeadline,
  attributionHeadline,
  servicesHeadline,
  thoughtsHeadline,
  aboutHeadline,
  linksHeadline,
  contactHeadline,
  unavailableHeadline,
  loadingMessage,
  deletingPensieroMessage,
  missingDataMessage,
};
