import { Fragment } from "react";
import { useLocation, Link } from "react-router-dom";
import { sections } from "../assets/text/sections";
import { type AvailableLanguage } from "../assets/data/types";
import { isOverflown } from "../utils/utils";

type NavBarProps = {
  selectedLanguage: AvailableLanguage;
};

export default function NavBar({ selectedLanguage }: NavBarProps) {
  const location = useLocation();

  const el = document.querySelector("nav");
  /* adapt row jc layout based on overflow */
  if (el) {
    el.style.justifyContent = isOverflown(el) ? "flex-start" : "center";
  }

  return (
    <nav
      className="w100pc row jc-center ai-center txt-14 space-25 bg-grad-e"
      style={{ paddingBottom: "4px" }}
    >
      {sections.map((s, i) => (
        <Fragment key={i}>
          {i > 0 && ` | `}
          <Link
            className={`a1 pad-5 radius-4 ${
              location.pathname === `/${s.route}` ? "bg-grad_fc0-var col-teal" : ""
            }`}
            to={`${s.route}`}
          >
            {s[selectedLanguage].toUpperCase()}
          </Link>
        </Fragment>
      ))}
    </nav>
  );
}
