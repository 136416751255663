// Todo: add types?

export const sections = [
  { route: "services", it: "Servizi", en: "Services" },
  { route: "about", it: "Info", en: "About" },
  { route: "contact", it: "Contatto", en: "Contact" },
  // { route: "ideas", it: "Idee", en: "Ideas" },
];

export const services = [
  {
    area: { it: "Ricerca", en: "Research" },
    subAreas: ["Dev Technology", "Frameworks", "Multimedia"],
  },
  {
    area: { it: "Sviluppo", en: "Development" },
    subAreas: [
      "UI",
      "Web Applications",
      "Media Players",
      "Data Visualisation",
      "Prototypes",
    ],
  },
  {
    area: { it: "Media temporali", en: "Time Media" },
    subAreas: ["Media Workflows", "Web A/V", "A/V Editing", "AudioVisual"],
  },
];

export const skills = [
  { name: "React", level: "experience" },
  { name: "GraphQL", level: "elements" },
  { name: "D3", level: "intro" },
];

export const about = {
  it: [
    "Oltre cinque anni di esperienza come sviluppatore   software e venti lavorando in studio, coi media digitali e il web.",
    "A seguito di studi in scienze della comunicazione, multimedia, musica, informatica e programmazione ho sviluppato interesse per diverse discipline, aree e industrie, con un insieme di competenze particolare e diversificato.",
    "Sono a disposizione per fare ricerca su una nuova tecnologia e aiutarti a sviluppare il progetto che hai in mente. Sono anche disponibile per prototipi di applicazioni.",
    "Se hai un'idea o una richiesta, per favore",
    "contattami",
    "https://profile.indeed.com/p/nicolag-ja202t7",
    "Il mio profilo",
    "su",
  ],
  en: [
    "Over five years of experience as a software developer and twenty working with studio workflows, digital media and the web.",
    "Following studies in communication sciences, multimedia, music and computing, I have developed an interest for multiple disciplines, areas and industries, as well as a rather personal and diverse skill set.",
    "I am happy to research new technology and help you build the project you have in mind. Also available for app prototypes.",
    "If you have an idea or an enquiry, please",
    "get in touch",
    "https://profile.indeed.com/p/nicolag-ja202t7",
    "My profile",
    "on",
  ],
};

export const contact = {
  it: [
    "Per qualsiasi richiesta di progetto o collaborazione, per favore contattami.",
    "nicola@fotonix.it",
    "Invia un'Email",
    "Sono basato a Londra ma ho anni di esperienza col lavoro in remoto, posso anche lavorare in altre città europee in quanto parlo quattro lingue.",
  ],
  en: [
    "Please get in touch if you have any questions about projects or collaborations.",
    "nicola@fotonix.it",
    "Send me an Email",
    "I am based in London but have experience working remotely, and can work in other European cities too as I can speak four languages.",
  ],
};
