import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getRandomInRange } from "../utils/generators";
import { type AvailableLanguage } from "../assets/data/types";

export default function RandomWidthsBar({
  generativeFunction, // could be static (instead of prop)
  sum,
  rangeDivider,
  height,
  selectedLanguage,
}: {
  generativeFunction: (arg0: number, arg1: number) => number[];
  sum: number;
  rangeDivider: number;
  height: number;
  selectedLanguage: AvailableLanguage;
}) {
  const pathname = useLocation().pathname;
  // const widths = generativeFunction(sum, rangeDivider); // once
  const [widths, setWidths] = useState<number[]>([]);

  useEffect(() => {
    const list = generativeFunction(sum, rangeDivider);
    setWidths(list);
  }, [pathname, generativeFunction, sum, rangeDivider]);

  /* some colors with rgb notation */
  const colors = {
    yellow: "255, 204, 0",
    orange: "255, 128, 0",
    teal: "0, 128, 128",
    salem: "30, 130, 76",
  };
  const baseColor = selectedLanguage === "it" ? colors.orange : colors.teal;
  return (
    <div className="w100pc row">
      {widths.map((w, i) => (
        <div
          key={i}
          style={{
            width: `${w}%`,
            height: `${height}px`,
            backgroundColor: `rgba(${baseColor}, ${getRandomInRange(0.3, 1)}`,
            // border: "1px solid #eee",
          }}
        />
      ))}
    </div>
  );
}
