import { attributionHeadline } from "../assets/text/headlines";
import { type AvailableLanguage } from "../assets/data/types";
import { generateListOfSum } from "../utils/generators";
import RandomWidthsBar from "./RandomWidthsBar";

type FooterProps = {
  selectedLanguage: AvailableLanguage;
  headerSet: boolean;
};

export default function Footer({ selectedLanguage, headerSet }: FooterProps) {
  if (!headerSet) return null;

  return (
    <footer className="bg-grad-e">
      <RandomWidthsBar
        height={8}
        generativeFunction={generateListOfSum}
        sum={100}
        rangeDivider={2}
        selectedLanguage={selectedLanguage}
      />
      <p className="pad-10 col-slategrey txt-12">
        {attributionHeadline[selectedLanguage]} kinolag | NicolaG, 2024
        {/* - Berlin, 11/2020 - London, 07/2024 (&copy;) */}
      </p>
    </footer>
  );
}
