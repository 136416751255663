import { type AvailableLanguage } from "../assets/data/types";
import { services } from "../assets/text/sections";
//import { servicesHeadline } from "../assets/text/headlines";

const backgrounds: Record<number, string> = {
  0: "bg-grad_gr2-fc0",
  1: "bg-grad_fc0-var",
  2: "bg-grad_f80-fc0",
};

type ServicesProps = {
  headerHeight: number;
  selectedLanguage: AvailableLanguage;
};

export default function Services({
  headerHeight,
  selectedLanguage,
}: ServicesProps) {
  return (
    <section className="pad-20 col ai-center" style={{ height: "100%" }}>
      {/* <div className="pad-5 space-25 col-teal bg-grad-e radius-4">
        {servicesHeadline[selectedLanguage].toUpperCase()}
      </div> */}
      <div className="col ai-center w100pc mar-10 txt-c space-08">
        {services.map((s, i) => (
          <div
            key={s.area[selectedLanguage]}
            className={`w100pc mar-10 pad-5 radius-4 border-1 ${backgrounds[i]}`}
            style={{ maxWidth: "400px" }}
          >
            <div className="col-teal pad-5 radius-4">
              {s.area[selectedLanguage].toUpperCase()}
            </div>
            {s.subAreas?.map((d) => (
              <div key={d} className="col-349 pad-5 txt-14">
                {d}
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
}
