import { type AvailableLanguage } from "../assets/data/types";
import { Link } from "react-router-dom";
import { contact } from "../assets/text/sections";
//import { contactHeadline } from "../assets/text/headlines";

type ContactProps = {
  headerHeight: number;
  selectedLanguage: AvailableLanguage;
};

export default function Contact({
  headerHeight,
  selectedLanguage,
}: ContactProps) {
  return (
    <section className="pad-20 col ai-center" style={{ height: "100%" }}>
      {/* <div className="pad-5 space-25 col-teal bg-grad-e radius-4">
        {contactHeadline[selectedLanguage].toUpperCase()}
      </div> */}
      <div className="col ai-center w100pc mar-10 txt-c space-08 line-height-150 col-teal">
        <div
          className={`w100pc mar-10 pad-5 radius-4 border-1 bg-grad-e`}
          style={{ maxWidth: "400px" }}
        >
          <div className="col-teal pad-5 txt-14">
            <p>{contact[selectedLanguage][0]}</p>
            <br />
            <p>
              <Link
                className="a2"
                to={`mailto:${contact[selectedLanguage][1]}?subject=itti.tech enquiry`}
                title="email"
              >
                {contact[selectedLanguage][2]}
              </Link>
            </p>
            <br />
            <p>{contact[selectedLanguage][3]}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
