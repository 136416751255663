import { BrowserRouter } from "react-router-dom";
import { useState } from "react";
import "./App.css";
import { type AvailableLanguage } from "./assets/data/types";
import Header from "./components/Header";
import ContentArea from "./components/ContentArea";
import Footer from "./components/Footer";

const mainStyles: React.CSSProperties = {
  border: "0px solid green",
  // position: "fixed",
  position: "relative",
  alignSelf: "center",
  width: "100%",
  height: "100%",
  /* the following would prevent vertical scrolling (without further strategies) */
  // overflow: "hidden",
};

function App() {
  /** settings/setup: starting bilingual (not user selected) */
  const languages: AvailableLanguage[] = ["en", "it"];

  const [selectedLanguage, setSelectedLanguage] = useState<AvailableLanguage>(
    languages[0]
  );
  /** setting h, w to 0 seems to prevent an issue with first render
   * a glitch is handled in chrome to prevent first height to be set twice, due to cerchio max-width, flex-shrink
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [headerWidth, setHeaderWidth] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  return (
    <div className="App">
      {/* Header inside Router to allow navigation */}
      <main className="outer bg-f" style={mainStyles}>
        <BrowserRouter>
          <Header
            languages={languages}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            setHeaderWidth={setHeaderWidth}
            setHeaderHeight={setHeaderHeight}
          />
          <ContentArea
            selectedLanguage={selectedLanguage}
            headerHeight={headerHeight}
          />
          <Footer
            selectedLanguage={selectedLanguage}
            headerSet={headerHeight > 0}
          />
        </BrowserRouter>
      </main>
    </div>
  );
}

export default App;
