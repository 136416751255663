// NB: to use NavLnk (or other Router features), Header must be inside <Router> (in <AppMain/>), even without a route
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { topHeadline } from "../assets/text/headlines";
import { lang } from "../assets/text/labels";
// Hexagon as component should work via the SVGR plugin
import Hexagon from "../assets/icons/Hexagon";
import { throttle } from "../utils/throttle";
import { type AvailableLanguage } from "../assets/data/types";
import NavBar from "../components/NavBar";

type HeaderProps = {
  languages: AvailableLanguage[];
  selectedLanguage: AvailableLanguage;
  setSelectedLanguage: (arg0: AvailableLanguage) => void;
  setHeaderWidth: (arg0: number) => void;
  setHeaderHeight: (arg0: number) => void;
};

const Header = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  setHeaderWidth,
  setHeaderHeight,
}: HeaderProps) => {
  const navigate = useNavigate();

  /* detect changing sizes of (window and) element */
  const [innerW, setInnerW] = useState(window.innerWidth);
  const [innerH, setInnerH] = useState(window.innerHeight);

  /* local state updater - effect step 1 */
  function setResize() {
    setInnerH(window.innerHeight);
    setInnerW(window.innerWidth);
  }

  /* set element/wrapper sizes in parent component so we can pass them from AppMain to other children (e.g. for their positioning) - and try to prevent the final state update from firing too many times (throttle - or other strategy like onmouseup, ondragend to achieve single update ?) */
  /* resizeObserver API may be worth a try */
  useEffect(() => {
    /* parent state updater - effect step 2 */
    function throttledUpdater(width: number, height: number) {
      function parentStateUpdater() {
        setHeaderWidth(width);
        setHeaderHeight(height);
      }
      // console.log(width, height)
      throttle(parentStateUpdater, 200);
    }

    // step 1, depending on window resize event
    window.addEventListener("resize", setResize);
    // step 2, depending on local state update (innerW, innerH)
    let width = document.getElementById("head")?.offsetWidth;
    let height = document.getElementById("head")?.offsetHeight;
    if (!!width && !!height) throttledUpdater(width, height);

    return () => window.removeEventListener("resize", setResize);
  }, [innerW, innerH, setHeaderHeight, setHeaderWidth]);

  return (
    <header id="head" className="col bg-f shadow-bbb">
      {/* <div className="mar-15 cell-rev w100pc"> */}
      <div className="row ai-center jc-between mar-5">
        {/* <div className="mar-15 row-col ai-center w100pc"> */}
        <div className="row ai-center">
          <div
            className="cell cerchio bg-grad_fc0-var mar-10"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            {/* Hexagon component at centre of cerchio, svg can be styled this way */}
            <Hexagon
              className="rotator"
              radius={20}
              style={{ width: "60%" }}
              color={selectedLanguage === "it" ? "teal" : "orangered"}
            />
            <Hexagon
              className="rotator"
              radius={20}
              style={{ width: "60%" }}
              color={selectedLanguage === "en" ? "teal" : "orangered"}
            />
          </div>
          <div className="var-wrapper pad-10 space-04">
            {/* was row-col see itti_full */}
            <p className="row col-teal">
              <span>I T T I</span>
            </p>
            <p className="pad-10 col-mus-red line-height-125">
              {topHeadline[selectedLanguage][0]}{" "}
              <span className="txt-14 col-teal no-display">
                {topHeadline[selectedLanguage][1]} JavaScript/TypeScript, React,
                Node, Express, MongoDB, Remix, D3, Python{" "}
                {topHeadline[selectedLanguage][2]}.
              </span>{" "}
              <br />
              <span className="">
                Web Audio/Video
              </span>
            </p>
          </div>
        </div>
        <div className="col ai-center">
          {languages.map((key) => (
            <button
              key={key}
              className="col-349 txt-12 "
              onClick={() => {
                if (selectedLanguage !== key) {
                  setSelectedLanguage(key);
                }
              }}
            >
              {lang[key].toUpperCase()}
            </button>
          ))}
        </div>
        {/* <button className="col-mus-red txt-14" onClick={() => navigate("/editor")}>
          <b>+</b>
        </button> */}
      </div>
      <NavBar selectedLanguage={selectedLanguage} />
    </header>
  );
};

export default Header;
