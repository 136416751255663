import { Route, Routes, Navigate } from "react-router-dom";
import Services from "./Services";
import About from "./About";
import Contact from "./Contact";
import { type AvailableLanguage } from "../assets/data/types";

type ContentAreaProps = {
  selectedLanguage: AvailableLanguage;
  headerHeight: number;
};

function ContentArea({ selectedLanguage, headerHeight }: ContentAreaProps) {
  const headerSet = headerHeight > 0;
  if (!headerSet) return null;

  return (
    /**
     * AppMain has position: relative, and we recalculate headerHeight on window resize.
     * we throttle via timeout, or useCallback, to limit the number of re-renders (which would also cause a data refetch if present).
     * we also check headerHeight to avoid a double render (one before it's been set).
     */
    <div
      className="w100pc"
      style={{ position: "absolute", top: `${headerHeight - 10}px` }}
    >
      <Routes>
        <Route path="/" element={<Navigate to="/services" />} />
        <Route
          path="/services"
          element={
            <Services
              headerHeight={headerHeight}
              selectedLanguage={selectedLanguage}
            />
          }
        />
        <Route
          path="/about"
          element={
            <About
              headerHeight={headerHeight}
              selectedLanguage={selectedLanguage}
            />
          }
        />
        <Route
          path="/contact"
          element={
            <Contact
              headerHeight={headerHeight}
              selectedLanguage={selectedLanguage}
            />
          }
        />
        {/* <Route path="/:badLink" element={NotFound} /> */}
      </Routes>
    </div>
  );
}

export default ContentArea;
