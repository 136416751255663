// v1
export function generateListOfSum(
  sum: number = 100, // percentages
  rangeDivider: number = 1
): number[] {
  let remaining = sum;
  let list: number[] = [];

  while (remaining > 0) {
    // smaller range determines smaller numbers
    const range = remaining / rangeDivider;
    // generate number within remaining range
    const addendo = Number((Math.random() * range).toFixed(2));

    // updates
    list.push(addendo);
    remaining -= addendo;
    // console.log(remaining);

    // early break
    if (remaining < sum / 20) {
      list.push(remaining);
      break;
    }
  }

  return list;
}

export function getRandomInRange(min: number = 0, max: number = 1): number {
  return Math.random() * (max - min) + min;
}
