/* hexagon generator (with horizontal top & bottom sides - CSS rotation can be added) */
/* https://it.wikipedia.org/wiki/Esagono */

const Hexagon = (props) => {
  const r = props.radius
  /* apotema */
  const a = r * 0.866025
  /** vertexes as function of r, a (sizes in pixels)
   * clockwise (starting from '9pm' spot)
   * repeating first vertex as last does not seem necessary
   */
  const points = `0,${r} 
  ${r / 2},${r - a} 
  ${r * 1.5},${r - a} 
  ${2 * r},${r} 
  ${r * 1.5},${r + a} 
  ${r / 2},${r + a}`
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${r * 2} ${r * 2}`}
      // passed props for className, color, width, height etc.
      {...props}
    >
      <polygon points={points} fill="none" stroke="currentColor" strokeWidth="1" />
    </svg>
  )
}

export default Hexagon
