import { type Label } from "../data/types";

// Translations of UI elements

const login: Label = {
  it: "Accesso",
  en: "Login",
  fr: "S'identifier",
  es: "Iniciar sesión",
  de: "Anmeldung",
};

const author: Label = {
  it: "Autore/trice",
  en: "Author",
  fr: "Auteur/e",
  es: "Autor/a",
  de: "Autor/in",
};

const lang: Label = {
  it: "Italiano",
  en: "English",
  fr: "Français",
  es: "Español",
  de: "Deutsch",
};

const title: Label = {
  it: "Titolo",
  en: "Title",
  fr: "Titre",
  es: "Título",
  de: "Titel",
};

const body: Label = {
  it: "Corpo",
  en: "Body",
  fr: "Corps",
  es: "Cuerpo",
  de: "Körper",
};

const topics: Label = {
  it: "Argomenti",
  en: "Topics",
  fr: "Sujets",
  es: "Temas",
  de: "Themen",
};

const topicLabel: Label = {
  it: "filtra per argomento:",
  en: "filter by topic:",
  fr: "",
  es: "",
  de: "",
};

const nuovo: Label = {
  it: "NUOVO",
  en: "NEW",
  fr: "",
  es: "",
  de: "",
};

const edit: Label = {
  it: "EDIT\xa0\xa0",
  en: "EDIT",
  fr: "",
  es: "",
  de: "",
};

const buttons: Record<string, Label> = {
  create: {
    it: "Salva",
    en: "Create",
    fr: "",
    es: "",
    de: "",
  },
  update: {
    it: "Aggiorna",
    en: "Update",
    fr: "",
    es: "",
    de: "",
  },
  cancel: {
    it: "Cancella",
    en: "Cancel",
    fr: "",
    es: "",
    de: "",
  },
  saving: {
    it: "Salvo...",
    en: "Saving...",
    fr: "",
    es: "",
    de: "",
  },
};

export {
  login,
  author,
  lang,
  title,
  body,
  topics,
  topicLabel,
  nuovo,
  edit,
  buttons,
};
