/** source: https://blog.bitsrc.io/improve-your-react-app-performance-by-using-throttling-and-debouncing-101afbe9055 */

export function throttle(func, ms) {
  let timeout
  function exec() {
    func.apply()
  }
  function clear() {
    if (timeout !== undefined) clearTimeout(timeout);
    // else null
  }
  if (func !== undefined && ms !== undefined) {
    timeout = setTimeout(exec, ms)
  } else {
    console.error("callback function and the timeout must be supplied")
  }
  // API to clear the timeout
  throttle.clearTimeout = function () {
    clear()
  }
}

// use it as follows
// function limiter() {
//   throttle(setYourState, 500)
// }
