import { type AvailableLanguage } from "../assets/data/types";
import { Link } from "react-router-dom";
import { about } from "../assets/text/sections";
//import { aboutHeadline } from "../assets/text/headlines";

type AboutProps = {
  headerHeight: number;
  selectedLanguage: AvailableLanguage;
};

export default function About({ headerHeight, selectedLanguage }: AboutProps) {
  return (
    <section className="pad-20 col ai-center" style={{ height: "100%" }}>
      {/* <div className="pad-5 space-25 col-teal bg-grad-e radius-4">
        {aboutHeadline[selectedLanguage].toUpperCase()}
      </div> */}
      <div className="col ai-center w100pc mar-10 txt-c space-08 line-height-150 col-teal">
        <div
          className={`w100pc mar-10 pad-5 radius-4 border-1 bg-grad_gr2-fc0`}
          style={{ maxWidth: "400px" }}
        >
          <div className="col-teal pad-5 txt-14">
            <p>{about[selectedLanguage][0]}</p>
            <br />
            <p>{about[selectedLanguage][1]}</p>
            <br />
            <p>{about[selectedLanguage][2]}</p>
            <br />
            <p>
              {about[selectedLanguage][3]}{" "}
              <Link className="a2" to="/contact">
                {about[selectedLanguage][4]}
              </Link>
              .
            </p>
          </div>
        </div>
        <div
          className={`w100pc mar-10 pad-5 radius-4 border-1 bg-grad-e`}
          style={{ maxWidth: "400px" }}
        >
          <div className="col-teal pad-5 txt-14">
            <p>
              <a
                className="a2"
                href={about[selectedLanguage][5]}
                target="_blank"
                rel="noreferrer"
                title="email"
              >
                {about[selectedLanguage][6]}
              </a>{" "}
              {about[selectedLanguage][7]} indeed.
            </p>
          </div>
        </div>
        {/* bottom spacer */}
        <div className="mar-10" />
      </div>
    </section>
  );
}
